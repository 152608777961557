import React, { useState } from "react"
import PropTypes from "prop-types"

import { SwitchTransition, CSSTransition } from "react-transition-group"

import { Link } from "gatsby"
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap"

import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"
import { TextFormater } from "../../global/textFormater"
// import ETECUBE_LOGO from "../../../assets/images/etecube.png"
import ETETECHNOLOGY_LOGO from "../../../assets/images/EteTechnology_LOGO_YATAY.png"
import TR_FLAG from "../../../assets/images/trFlag.png"
import DARK_TR_FLAG from "../../../assets/images/darkTrFlag.png"
import EN_FLAG from "../../../assets/images/enFlag.png"
import DARK_EN_FLAG from "../../../assets/images/darkEnFlag.png"

import "./styles.scss"

const Header = ({ headerTextMode, headerBackgroundColor }) => {
  const [state, dispatch] = useGlobalState()
  const [isClickMenu, setIsClickMenu] = useState(false)

  return (
    <Container
      style={{ backgroundColor: headerBackgroundColor }}
      fluid
      className="header-container"
    >
      <Container>
        <Navbar className="navbar" expand="md">
          <Navbar.Brand href="/">
            <img src={ETETECHNOLOGY_LOGO} alt="etetechnology logo" />
          </Navbar.Brand>
          <SwitchTransition mode={"out-in"}>
            <CSSTransition
              key={isClickMenu}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false)
              }}
              classNames="fade"
            >
              <div className="button-container">
                <Navbar.Toggle
                  className={
                    headerTextMode === "light"
                      ? isClickMenu
                        ? "lightClose"
                        : "lightMenu"
                      : isClickMenu
                      ? "darkClose"
                      : "darkMenu"
                  }
                  aria-controls="navbarResponsive"
                  onClick={() => setIsClickMenu(!isClickMenu)}
                />
              </div>
            </CSSTransition>
          </SwitchTransition>
          <Navbar.Collapse id="navbarResponsive" className="navbar-collapse">
            <Nav as="ul">
              <Nav.Item as="li">
                <Link
                  to="/about-us"
                  className="nav-link"
                  activeClassName="active"
                  style={{
                    color: headerTextMode === "light" ? "#FFFFFF" : "#333333",
                  }}
                >
                  {getLanguageFile(state.language).Header.aboutUs}
                </Link>
              </Nav.Item>
              <NavDropdown
                title={getLanguageFile(state.language).Header.products}
                id="nav-dropdown"
                className={headerTextMode}
              >
                {/* <NavDropdown.Item href="/products/nginrS">
                  <p className="product-name">nginr</p>
                  <p>{TextFormater("<0>®</0>")}</p>
                  <p className="product-name">s</p>
                </NavDropdown.Item> */}
                <NavDropdown.Item href="/products/nginr">
                  <p className="product-name">nginr</p>
                  <p>{TextFormater("<0>®</0>")}</p>
                  {/* <p className="product-name">m</p> */}
                </NavDropdown.Item>
                <NavDropdown.Item href="/products/hymots">
                  <p className="product-name">hymots</p>
                  <p>{TextFormater("<0>®</0>")}</p>
                </NavDropdown.Item>
                <NavDropdown.Item href="/products/etecube">
                  <p className="product-name">etecube</p>
                  <p>{TextFormater("<0>®</0>")}</p>
                </NavDropdown.Item>
              </NavDropdown>
              {/* <AnchorLink
    to="/Home#solutions"
    // title="Check out our team!"
    className="nav-link"
    activeClassName="active"
    style={{
      color: headerTextMode === "light" ? "#FFFFFF" : "#333333",
    }}
    stripHash
  /> */}
              <Nav.Item as="li">
                <Link
                  to="/#ETESolutions"
                  className="nav-link"
                  activeClassName="active"
                  style={{
                    color: headerTextMode === "light" ? "#FFFFFF" : "#333333",
                  }}
                >
                  {getLanguageFile(state.language).Header.solutions}
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  to="/career"
                  style={{
                    color: headerTextMode === "light" ? "#FFFFFF" : "#333333",
                  }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {getLanguageFile(state.language).Header.career}
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  to="/contact-us"
                  style={{
                    color: headerTextMode === "light" ? "#FFFFFF" : "#333333",
                  }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {getLanguageFile(state.language).Header.contactUs}
                </Link>
              </Nav.Item>
              <Nav.Item className="language-option" as="li">
                <div
                  className="language-button nav-link"
                  onClick={() =>
                    dispatch({
                      ...state,
                      displayNewYearModal: true,
                      language: state.language === "en" ? "tr" : "en",
                    })
                  }
                >
                  <img
                    src={
                      state.language === "en"
                        ? headerTextMode === "light"
                          ? TR_FLAG
                          : DARK_TR_FLAG
                        : headerTextMode === "light"
                        ? EN_FLAG
                        : DARK_EN_FLAG
                    }
                  />
                  <h6
                    style={{
                      color: headerTextMode === "light" ? "#FFFFFF" : "#333333",
                    }}
                  >
                    {(state.language === "en" ? "tr" : "en").toUpperCase()}
                  </h6>
                </div>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </Container>
  )
}

Header.propTypes = {
  headerTextMode: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
}

Header.defaultProps = {
  headerTextMode: "dark",
  headerBackgroundColor: "white",
}

export default Header
