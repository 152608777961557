/* eslint-disable no-mixed-spaces-and-tabs */
export default {
	"Header": {
		"aboutUs": "About Us",
		"products": "Products",
		"solutions": "Solutions",
		"career": "Career",
		"career_detail": "Career Details",
		"contactUs": "Contact Us"
	},
	"Title": {
    "aboutUs": "About Us",
    "products": "Products",
	"solutions": "Solutions",
		"career": "Career",
		"career_detail": "Career Details",
    "contactUs": "Contact Us"
	},
	"Footer": {
		"footerText": "Research | Analysis | Design | Implementation | Productization | Operations & Maintenance",
		"copyRight": `© Copyright 2020 ete technology<0>®</0>. All Rights Reserved.`,
		"products": "Products",
		"solutions": "Solutions",
		"aboutUs": "About Us",
		"news": "News",
		"contactUs": "Contact Us",
		"clarification":"Personal Data",
		"followUs": "Follow Us"

	},
	"NewsCard": {
		"readMore": "read more"
	},
	"NewsModal": {
		"description": "ete technology<0>®</0> has received “The Highest Number of Patent and Trademark Applications in 2019 Award” from Teknopark İZMİR.",
 		"descriptionMorePartOne": "This award was presented by Prof. Dr. Yusuf Baran, Rector of IYTE, at the ceremony held at IYTE Show Center on 16th November, 2020.",
		"descriptionMorePartTwo": "As ete technology<0>®</0> family, we thank the Teknopark İzmir Board of Directors and all our teammates for their contributions.",
		"descriptionMorePartThree": "",
		"readMore": "READ MORE"
	},
	"Home": {
		"mainDescriptionTitle": "We actualize \nchallenging ideas!",
		"mainDescription": "We research, analyze, design, implement, productize and operate. In the journey from an idea to a product, efficiency and total quality are always our guide.",
		"aboutUsButton": "ABOUT US",
		"eteSolutionDescriptionPartOne": "ete technology<0>®</0> develops education training experimentation (ETE) technologies for security, safety, defense and medicine. We offer our products which are based on our patented technologies as licensed on site solutions or in the form of platform or software as a service.",
		"eteSolutionDescriptionPartTwo": "ete technology<0>®</0> also tailors it’s products to the exact requirements of the customers when requested. We design and deliver capabilities including our products and provide operations and maintenance support whenever and wherever needed.",
		"eteSolutionDescriptionPartThree": "Our expertise includes multichannel human machine interface, big data and information fusion, explainable artificial intelligence, augmented intelligence, hyperautomation, mechatronic, modelling and simulation, serious gaming, datacenters and cyber security.",
		"contactUsDescription": "If you have an idea to actualize!",
		"contactUsButton": "GET CONNECTED",
		"products": "Products",
		"solutions": "Solutions",
		"ephesus": "Joint Warfare and Training Centers",
		"ephesusDescription": "ete technology<0>®</0> has delivered joint warfare and training centers. We develop concept of operations, prove the concept, design the center and deliver it as a capability including it’s doctrine, facilities, material and training. ete technology<0>®</0> has the complete set of software products for a joint warfare and training center that can be tailored according to the needs of the customers.",
		"modelingAndSimulation": "Joint Intelligence Surveillance Reconnaissance Fusion Centers",
		"modelingAndSimulationDescription": "ete technology<0>®</0> delivers joint intelligence surveillance reconnaissance (ISR) fusion centers as a capability package including their doctrine, facilities, material and training. ete technology<0>®</0> has the complete set of software products for a joint ISR center including open source, measurement and signature intelligence, in short OSINT and MASINT relatively, processing and fusion software with advanced artificial intelligence support.",
		"eteTrainings": "ETE3 Training",
		"eteTrainingsDescription": "ete technology<0>®</0> provides courses on the planning, preparation and execution of the ETE3 events. Please click <1>here</1> to see the full list of courses.",
		"dataServices": "Data Services",
		"dataServicesDescription": "ete technology<0>®</0> is a research and development company on data science including data engineering, data management, data analytics, data security and privacy. We have developed expertise and proprietary tools related to big data, machine learning, data analytics and data mining. ete technology<0>®</0> offers its expertise and tools to provide tailored data science support.",
		"eteServices": "ETE3 Services",
		"eteServicesDescription": "ete technology<0>®</0> provides services to support Education Training Exercises Experimentations and Evaluations (ETE3)."
	},
	"AboutUs": {
		"ceoDescriptionTitle": "We actualize challenging ideas!",
		"ceoDescription": "We develop education training experimentation (ETE) technologies for security, safety, defense and medicine. We actualize challenging ideas and have products, brands and international patents. Data science, explainable artificial intelligence, multi-channel human machine interaction, augmented intelligence, modeling and simulation, serious games are among our expertise.\n\nete technology<0>®</0> staff consist of talented researchers and engineers. Our international footprint contributes to our competence. ete technology<0>®</0> aims to sustain a position at the pioneering edge in the field of education training and experimentation technologies. Being innovative, reliable, transparent and sustainable are among our principles.\n\nTülin ÇAYIRCI\nCOO",
		"team": "ete technology<0>®</0>",
		"teamDescription": "Takes its main strength from innovative, passionate and highly motivated minds!",
		"founderAndChairman": "Founder and Chairman",
		"ceo": "Chief Executive Officer - CEO",
		"coo": "Chief Operating Officer - COO",
		"chiefTechnologyOfficer": "Chief Technology Officer - CTO",
		"qualityAssurance": "Quality Assurance Engineer - Quality Assurance Department (QAD)",
		"humanResources": "Specialist - Human Resources Department (HRD)",
		"bigDataTeamLeader": "Team Leader - Big Data and Information Fusion (Big Data)",
		"hmiTeamLeader": "Team Leader - Multi-Channel Human Machine Interface (HMI)",
		"aiTeamLeader": "Team Leader - Explainable AI and Augmented Intelligence (AI)",
		"msTeamLeader": "Team Leader - Modeling, Simulation and Gaming (M&S)",
		"cyberSecurityTeamLeader": "Team Leader - Datacenter and Cyber Security (Cyber)",
		"news": "News",
		"firstNew": "As of the end of April, we developed the prototype of our hybrid multi-domain operations and tactics simulator (hymots<0>®</0>)...",
		"secondNew": "Within the scope of R&D and technology management activities by the Presidency of Defense Industries, our founder Prof Dr Erdal Çayırcı will run...",
		"thirdNew": "We signed the HAMLE project, one of the 17 R&D projects planned by the Presidency of Defense Industries for 2020, as a solution partner...",
		"fourthNew": "ete technology<0>®</0> has received “The Highest Number of Patent and Trademark Applications in 2019 Award” from Teknopark İZMİR.",
		"nginrNew": "nginr<0>®</0>, enformasyon bütünleştirme konusunda her geçen gün daha geniş alanlarda kullanılmaya devam ediyor.",
		"hamleNew": `Savunma Sanayii Başkanlığı bünyesinde gerçekleştirilen AR-GE Projeleri kapsamında, alt yüklenicisi olduğumuz "Hareket Tarzı Geliştirilen Yapay Zeka Komutan Asistanı Geliştirme (HAMLE) Projesi’nin” ilk entegrasyonunu Aselsan A.Ş. ile birlikte 3 Şubat 2021’de başarıyla tamamladık.`,
		"hamleNew2": `Savunma Sanayii Başkanlığı bünyesinde gerçekleştirilen AR-GE Projeleri kapsamında, alt yüklenicisi olduğumuz "Hareket Tarzı Geliştiren Yapay Zekâlı Komutan Asistanı (HAMLE) Projesi’nin” ikinci entegrasyonunu Aselsan A.Ş. ile 25 Şubat 2021 tarihinde başarıyla tamamladık.`,
		"fifthYear":`Şirketimizin 5. kuruluş yıldönümünde çalışanlarımızı inançlı ve başarı odaklı çalışmalarından dolayı tebrik eder, iş ortaklarımıza bize duydukları güven için teşekkür ederiz. Bu sorumluluğun bilinciyle bilimsel düşünmeye, teknoloji üretmeye ve paylaşmaya devam edeceğiz. Nice yıllara!\nTülin ÇAYIRCI - Genel Müdür`,
		"hymotsg1": "hymots<0>®</0>g, is used in a real operational setting: hymots<0>®</0>g met it’s first users after three year development. It was successfully used in a geostrategic analysis task by its first operational users. Therefore, we declare it’s technology readiness level as nine which is the highest.",
		"nginrNews1": "Integrated Security and Safety System nginr<0>®</0>s technology readiness level is at the service of our users with TRL9.",
		"orientation1": "We have completed the first training meeting of the year.",
		"hackathonNews1": "We would like to thank Teknopark İzmir and ITU Arı Teknokent for organizing the HACKATHON event with which we cooperate. We wish success to all teams.",
		"summerActiveNews1": "As the ete technology<0>®</0> family, we held our 2022 summer event at Titus Feronia in Urla. We left behind an event full of fun and pleasure, far from city life, intertwined with nature.",
		"havelsanNews1": "We signed a cooperation protocol with Havelsan Inc. in IDEF 2023.",
		"ca2x2News1": "We are delighted to be part of the 18th NATO CA2X2 (Computer Assisted Analysis, Exercise, Experimentation) Forum 2023 (Rome, from 3-5 October.) organized by the NATO Modelling and Simulation Centre of Excellence.",
		"samaFatNews": "Our Software and Training Services on Information and Knowledge Management (IKM) project's Factory Acceptance Test (FAT - SAMA 0.0.0.0) has been successfully completed.\n\nCongratulations to all our team members who contributed to this achievement.",
		"foeNews1": "The Future Operating Environment Wargame of NATO was supported successfully with etecube® and hymots<0>®</0> in Paris on 27th March, 2024.",
		"foeNews2": "Our products etecube<0>®</0>, hymots<0>®</0> and nginr<0>®</0> are participating in the next NATO Federation on HLA 4.0 and NATO Education Training Network FOM within NATO Technical Activity MSG 223.",
		"orientation2": `We have successfully completed the "Foundations of Management" module, an important stage of the "Management Academy" training program! 🎉📚 With the knowledge we have gained, we will continue to progress with motivation and determination to contribute to the success of our company.`,
		"havelsanTskNews1": "On May 27-28, our products were presented to the personnel of the Turkish Armed Forces  and the Ministry of National Defense at HAVELSAN A.Ş. facilities.",
		"viewAllNews": "View All"
	},
	"JobApply": {
		"softwareEngineerTitle": "Junior Software Engineer",
		"partTimeTitle": "Part Time",
		"succesfullApply": "Your application has been submitted!",
		"succesfullApplyDescription": "Expect a response shortly after we have reviewed your application.",
		"homeButton": "HOME",
		"firstName": "First Name*",
		"lastName": "Last Name*",
		"email": "E-mail  address*",
		"dateOfBirth": "Date Of Birth* (dd/mm/yyyy)",
		"city": "City*",
		"personalWebsite": "Personal Website",
		"twitter": "Twitter",
		"linkedIn": "LinkedIn",
		"facebook": "Facebook",
		"education": "Education*",
		"experience": "Experience*",
		"portfolio": "Portfolio drop your files here",
		"orSelectFile": "or select file",
		"isRequired": "This field is required.",
		"invalidEmail": "This is not a valid e-mail.",
		"invalidDate":"Invalid format (dd/mm/yyyy).",
		"invalidUrl": "This is not a valid URL.",
		"apply": "APPLY"

	},
	"ETECube":{
		"etecubeBooklet":"Please click <1>here</1> to see the etecube<0>®</0> booklet."
	},
	"Nginr": {
		"nginrMainDescription": "The platform to retrieve information from big data and to validate and fuse the information into a blocknetwork centric knowledgebase.",
		"nginrDescriptionPartOne": "Engine for recognition and fusion (nginr<0>®</0>) is a big data analytics and fusion platform enabling actionable intelligence for decision support and automation. The feature rich and high performance platform can be configured to meet domain and application specific needs. It is a perfect fit when you are building solutions to generate actionable intelligence from real-time streams of Big Data. Move beyond analytics from single homogenous datasets and instead make sense of multiple streams of heterogenous datasets with our augmented data analytics platform. Our actionable intelligence approach means that the analytics are prepared for human decision making as well as consumption by machines for automation purposes.",
		"nginrDescriptionPartTwo": "The platform has been designed to meet the demanding applications with requirements such as real-time continuous intelligence, security and strong authentication, data integrity and accountability. Bring Your Own Data or use our data access services to discover, source, prepare and integrate the open data sets needed. With access to big data in place the platform will process the data using artificial intelligence and machine learning techniques. We have a diverse set of our own proprietary components with ready trained models for machine learning from a variety of data types such as text, images, audio and video. Integration of the 3rd party models is supported and as well as time series and measurements from sensors such as location, pressure, temperature. Any concept identified and their interrelations are organized and recorded in a Blocknetwork. The Blocknetwork technology is developed and patented by Dataunitor AS. The architecture of the platform is distributed, modular and component based making it scalable by design. The multiple instances of the same module, typically thousands of them, may be active simultaneously. The components make heavy use of hardware acceleration (GPU) processing techniques.",
		"nginrDescriptionPartThree": "nginr<0>®</0> can be provided under a Platform as a Service (PaaS) software subscription model. The PaaS service is running on a highly optimized operating system in our own datacenter. Get in touch to discuss your needs and any special requirements such as on-premises deployment and access to source code.",
		// "nginrDescriptionPartFour": "Please click <1>here</1> to see the nginr<0>®</0>s booklet.",
		// "nginrDescriptionPartFive": "Please click <1>here</1> to see the nginr<0>®</0>m booklet."
	},
	"Hymots": {
		"hymotsMainDescription": "The micro agent-based modeling and simulations platform enabling digital twins, serious games, experimentation, and training solutions.",
		"hymotsDescriptionPartOne": "Hybrid multi-domain operations and tactics simulator (hymots<0>®</0>) is a micro agent-based military constructive simulation platform, which is designed to achieve high scalability, performance and fidelity. The computations are highly parallelized enabled by hardware accelerated (GPU) processing techniques. The platform is developed to build an operations and tactics simulator for all domains including land, air, sea, space and cyber. It also includes a geostrategic game solution for hybrid environments.",
		"hymotsDescriptionPartTwo": "An immersive user-experience based on a high definition 3D graphics is enabled by using the latest game engine rendering capabilities. The platform is enabled with a diverse set of ready and configurable components which can be orchestrated using a drag and drop graphical user interface. Examples of components are terrains, buildings, vehicles, weather, space weather, logistics, human and social behavior. Solutions can be deployed and accessed remotely in an MSaaS configuration supported by high performance simulations running in a datacenter. Our on-premise deployment options include local offline and even mobile installations.",
		"hymotsDescriptionPartThree": "The system also has modules to analyze and manage the exercise, training and experimentation objectives, to measure how they are met, to design and manage main incident lists and to synchronize them with simulations.",
		"hymotsDescriptionPartFour": "ete technology<0>®</0> staff includes internationally recognized experts on military experiments, exercises and wargames. They support the activities of our customers and deliver training on planning and programming exercises, developing settings, scenarios, main incident lists.",
		"hymotsDescriptionPartFive":"Please click <1>here</1> to see the hymots<0>®</0>s booklet.",
	},
	"ContactUs": {
		"address": "Our Offices",
		"mail": "Mail",
		"phone": "Phone",
		"fax": "Fax",
		"workingHours": "Working Hours",
		"yourNamePlaceholder": "Full Name*",
		"yourMailPlaceholder": "E-mail address*",
		"yourMessagePlaceholder": "Message*",
		"isRequired": "This field is required.",
		"invalidEmail": "This is not a valid e-mail.",
		"sendButton": "SEND",
		"clarificationText": "I agree to ",
		"clarificationTextPopup": "clarification text"
	},
	"Career": {
		"introductionTitle": "We always value original ideas and talent!",
		"introductionDescriptionOne": "· Take one more step towards your career goals.",
		"introductionDescriptionTwo": "· Work with dedicated, creative and dynamic people.",
		"introductionDescriptionThree": "· Adapt to evolving and changing technologies.",
		"introductionDescriptionFour": "· Discover and experience your talent.",
		"secondTitle": "We look for talents to join our one team!",
		"jobDescription": "We always value original idea and talent. If you would like to be a part of the team that thinks and produces, let's meet up.",
		"internJobDescription":"Internship",
		"partTimeJobDescription":"Part Time Model",
		"internDescription":"ete technology<0>®</0> shares its expertise and experience with creative and motivated university students. We offer internship opportunities.",
		"partTimeDescription":"Many university students gain work experience in various positions at ete technology<0>®</0>.",
		"softwareEngineerDescription":"ete technology<0>®</0> is seeking new teammates for its Teknopark İzmir Engineering Bench.",
		"softwareEngineer": "Junior Software Engineer",
		"msApplication": "Software Engineer",
		"msApplicationDescription": "We are looking for new teammates in our Modeling, Simulation and Gaming Department.",
		"hmiApplication": "Web Application Developer",
		"hmiApplicationDescription": "We are looking for new teammates in our Multi-Channel Human Machine Interface Department.",
		"nlpApplication": "Software Engineer",
		"nlpApplicationDescription": "We are looking for new teammates in our Explainable AI and Augmented Intelligence Department.",
		"hmiApplication2": "Node.js Developer",
		"hmiApplicationDescription2": "We are looking for new teammates in our Multi-Channel Human Machine Interface Department.",
		"saApplication": "System Administrator",
		"saApplicationDescription": "We are looking for System Administrator to join our growing engineering team.",
		"bdiApplication": "Software Engineer",
		"bdiApplicationDescription": "We are looking for Software Engineer to join our growing engineering team.",
		"eeApplication": "Electrical and Electronics Engineer",
		"eeApplicationDescription":"We are looking for Electrical and Electronics Engineer to join our growing engineering team.",
		"gameApplication": "Game Developer",
		"gameApplicationDescription": "We are looking for Game Developer to join our growing engineering team.",
		"intern":"Intern",
		"partTime":"Part Time",
		"detailsButton": "DETAILS",
		"seeLess": "See Less",
  	"viewAll": "View All",
	},
	"CareerDetail": {
		"softwareEngineer_001GeneralQualificationsTitle":"General Qualifications",
		"softwareEngineer_001RequiredSkillsTitle":"Required Skills",
		"softwareEngineer_001":"Junior Software Engineer",
		"intern":"The first step to your career in ete technology<0>®</0>",
		"internFirstDescription":"ete technology<0>®</0> shares its expertise and experience with creative and motivated university students. We offer internship opportunities and part time positions.",
		"internRequiredSkillsTitle":"We can support your first step in your career!",
		"internRequiredSkills":"ete technology<0>®</0> provides students with a real setting where they can gain further insight into business and engineering under the mentorship of experienced ete technology<0>®</0> staff. \n\nInternship applications are accepted in February and March every year. The selected candidates are given the opportunity to work with us typically during the summer months. \n\nDo you want to gain experience that you can benefit from throughout your business life?",
		"internPositionInformation":"We are sorry that you cannot apply right now. The internship applications are accepted between February 1 and March 31.",
		"partTime":"ete technology<0>®</0> Part Time Model",
		"partTimeFirstDescription":"Many university students gain work experience in various positions at ete technology<0>®</0>. They actively participate in challenging projects and immersed in exciting and demanding business cases. The work environment in ete technology<0>®</0> provides the interns and part timers with experience which help them to determine what to focus on during the remaining part of their education.\n\n\nThe university students can work 2 days a week in various departments in our Teknopark Izmir Engineering Bench. Applications are accepted through ete technology<0>®</0> Career or online postings from our LinkedIn account.",
		"softwareEngineer_001PositionInformationTitle":"Position Details",
		"softwareEngineer_001FirstDescription":"\nWe are looking for a Software Engineer to join our growing engineering team.",
		"softwareEngineer_001GeneralQualifications":"\n· Bachelor's or Master’s degree in cryptography, computer science, mathematics or another related field\n· Good spoken and written English \n· Teamwork and result oriented \n· Between the ages of 22-28 \n· No military obligation for male candidates",
		"softwareEngineer_001RequiredSkills":"\n· Strong proficiency in C and/or C++ \n· Experience with practical cryptography \n· Good understanding of zero knowledge proofs, bulletproofs, elliptic curve cryptography, and prominent signature schemes \n· Good understanding of the security operations concepts: perimeter defense, data loss protection, insider threat, kill chain analysis, risk assessment, and security metrics \n· Basic understanding of the security devices like Firewall, IPS, WAF, NAC, DLP, FIM, PIM, Endpoint Security, etc. \n· Basic knowledge of TCP/IP and general networking principles",
		"softwareEngineer_001PositionInformation":"\n· Department: Big Data and Information Fusion \n· Position name: Software Engineer \n· Employment: Full-time",
		"softwareEngineer_002GeneralQualificationsTitle":"General Qualifications",
  	"softwareEngineer_002RequiredSkillsTitle":"Required Skills",
  	"softwareEngineer_002PreferredTitle":"Preferred Skills",
  	"softwareEngineer_002":"Software Engineer",
  	"softwareEngineer_002PositionInformationTitle":"Position Details",
  	"softwareEngineer_002FirstDescription":"\nWe are looking for Software Engineers to join our growing engineering team.",
  	"softwareEngineer_002GeneralQualifications":"\n· Bachelor's or Master's degree in computer science, mathematics or another related field\n· Good spoken and written English\n· Teamwork and result oriented\n· Between the ages of 22-28\n· No military obligation for male candidates",
  	"softwareEngineer_002RequiredSkills":"\n· Experience and good knowledge in C/C++ programming languages\n· Good understanding of Object Oriented Software concepts",
  	"softwareEngineer_002Preferred":"\n· Experience with CUDA, GPU programming",
		"softwareEngineer_002PositionInformation":"\n· Department: Modeling, Simulation and Gaming\n· Position name: Software Engineer\n· Employment: Full-time",
		"softwareEngineer_003GeneralQualificationsTitle":"General Qualifications",
  	"softwareEngineer_003RequiredSkillsTitle":"Required Skills",
  	"softwareEngineer_003PreferredTitle":"Preferred Skills",
  	"softwareEngineer_003":"Web Application Developer",
  	"softwareEngineer_003PositionInformationTitle":"Position Details",
  	"softwareEngineer_003FirstDescription":"\nWe are looking for Web Application Developer to join our growing engineering team.",
  	"softwareEngineer_003GeneralQualifications":"\n· Bachelor's or Master’s degree in computer science, software engineering or another related field\n· Good spoken and written English\n· Teamwork and result oriented\n· Between the ages of 22-28\n· No military obligation for male candidates",
  	"softwareEngineer_003RequiredSkills":"\n· Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model\n· Thorough understanding of React.js and its core principles\n· Experience with popular React.js workflows (such as Flux or Redux)\n· Familiarity with modern front-end build pipelines and tools\n· Experience with common front-end development tools such as Babel, Webpack, NPM, etc.\n· Clean and optimized programming",
  	"softwareEngineer_003Preferred":"\n· Familiarity with modern JavaScript coding standards\n· Familiarity with Typescript\n· Familiarity with RESTful APIs\n· Familiarity with server-side JavaScript (NodeJS, Express)\n· Familiarity with web mapping libraries (LeafletJS)",
		"softwareEngineer_003PositionInformation":"\n· Department: Multi-Channel Human Machine Interface\n· Position name: Software Engineer\n· Employment: Full-time",
		"softwareEngineer_004GeneralQualificationsTitle":"General Qualifications",
  	"softwareEngineer_004RequiredSkillsTitle":"Required Skills",
  	"softwareEngineer_004PreferredTitle":"Preferred Skills",
  	"softwareEngineer_004":"Software Engineer",
  	"softwareEngineer_004PositionInformationTitle":"Position Details",
  	"softwareEngineer_004FirstDescription":"\nWe are looking for Software Engineers to join our growing engineering team.",
  	"softwareEngineer_004GeneralQualifications":"\n· Bachelor's or Master's degree in computer science, mathematics or another related field\n· Good spoken and written English\n· Teamwork and result oriented\n· Between the ages of 22-28\n· No military obligation for male candidates",
  	"softwareEngineer_004RequiredSkills":"\n· Experience and good knowledge in Python and/or C/C++ programming languages\n· Good understanding of Object Oriented Software concepts\n· Proficiency in topics like statistic, machine learning and deep learning\n· Understanding of NLP/NLU tasks like text classification, NER, NLI etc.\n· Experience with deep learning frameworks like Keras, PyTorch or Tensorflow\n· Willingness to investigate academia and implement solutions for our NLP/NLU tasks",
  	"softwareEngineer_004Preferred":"\n· Experience with Automatic Speech Recognition tasks such as text to speech, speaker diarization, speaker identification with voice etc.",
		"softwareEngineer_004PositionInformation":"\n· Department: Explainable AI and Augmented Intelligence\n· Position name: Software Engineer\n· Employment: Full-time",
		"softwareEngineer_005GeneralQualificationsTitle":"General Qualifications",
  	"softwareEngineer_005RequiredSkillsTitle":"Required Skills",
  	"softwareEngineer_005PreferredTitle":"Preferred Skills",
  	"softwareEngineer_005":"Node.js Developer",
  	"softwareEngineer_005PositionInformationTitle":"Position Details",
  	"softwareEngineer_005FirstDescription":"\nWe are looking for Node.js Developer to join our growing engineering team.",
  	"softwareEngineer_005GeneralQualifications":"\n· Bachelor's or Master’s degree in computer science, software engineering or another related field\n· Good spoken and written English\n· Teamwork and result oriented\n· Between the ages of 22-28\n· No military obligation for male candidates",
  	"softwareEngineer_005RequiredSkills":"\n· Strong proficiency in JavaScript\n· Knowledge of Node.js and frameworks, libraries available for it\n· Basic understanding of front-end technologies, such as HTML5, and CSS3\n· Design and implement backend application interfaces for data and services\n· Clean and optimized programming",
  	"softwareEngineer_005Preferred":"\n· Familiarity with WebSocket protocol\n· Familiarity with Typescript / ES6\n· Familiarity with RESTful APIs\n· Familiarity with database systems (PostgreSQL, MongoDB etc.) and Object Relational Mapping (Sequelize)",
		"softwareEngineer_005PositionInformation":"\n· Department: Multi-Channel Human Machine Interface\n· Position name: Software Engineer\n· Employment: Full-time",
		"sysadmin_001GeneralQualificationsTitle":"General Qualifications",
  	"sysadmin_001RequiredSkillsTitle":"Required Skills",
  	"sysadmin_001":"System Administrator",
  	"sysadmin_001PositionInformationTitle":"Position Details",
  	"sysadmin_001FirstDescription":"\nWe are looking for System Administrator to join our growing engineering team.",
  	"sysadmin_001GeneralQualifications":"\n· Bachelor's or associate degree in computer programing, information systems, computer science or another related field\n· Good spoken and written English\n· Teamwork and result oriented\n· Between the ages of 22-28\n· No military obligation for male candidates",
  	"sysadmin_001RequiredSkills":"\n· Experience in AWS technologies (EC2, EBS etc.)\n· Administration of Linux servers and mail servers\n· Basic knowledge about networking, virtualization technologies and firewalls is a must (VMware)\n· The ability to maintain application servers and web servers is a plus",
		"sysadmin_001PositionInformation":"\n· Department: Datacenter\n· Position name: System Administrator\n· Employment: Full-time",
		"softwareEngineer_006GeneralQualificationsTitle":"General Qualifications",
  	"softwareEngineer_006RequiredSkillsTitle":"Required Skills",
  	"softwareEngineer_006":"Software Engineer",
  	"softwareEngineer_006PositionInformationTitle":"Position Details",
  	"softwareEngineer_006FirstDescription":"\nWe are looking for Software Engineer to join our growing engineering team.",
  	"softwareEngineer_006GeneralQualifications":"\n· Bachelor's or Master’s degree in computer science or another related field\n· Good spoken and written English\n· Teamwork and result oriented\n· Between the ages of 22-28\n· No military obligation for male candidates",
  	"softwareEngineer_006RequiredSkills":"\n· Experience and good knowledge in Python programming language\n· Good understanding of Object Oriented Software concepts\n· Understanding of HTML, HTTP, Rest APIs\n· Experience with Web Crawling/Scraping Tools (Scrapy Framework)\n· Familiar with version control systems like Git, SVN, etc.\n· Experience with Unix based operating systems",
		"softwareEngineer_006PositionInformation":"\n· Department: Big Data and Information Fusion\n· Position name: Software Engineer\n· Employment: Full-time",
		"gameDeveloper_001GeneralQualificationsTitle":"General Qualifications",
  	"gameDeveloper_001RequiredSkillsTitle":"Required Skills",
  	"gameDeveloper_001":"Game Developer",
  	"gameDeveloper_001PositionInformationTitle":"Position Details",
  	"gameDeveloper_001FirstDescription":"\nWe are looking for Game Developer to join our growing engineering team.",
  	"gameDeveloper_001GeneralQualifications":"\n· Bachelor's or Master’ s degree in computer science, software engineering or another related field\n· Good spoken and written English\n· Teamwork and result oriented\n· Between the ages of 22-28\n· No military obligation for male candidates",
  	"gameDeveloper_001RequiredSkills":"\n· Good knowledge in C++ programming skills\n· Experience with the following technologies; Unity3D, Unreal Engine",
		"gameDeveloper_001PositionInformation":"\n· Department: Modeling, Simulation and Gaming (M&S)\n· Position name: Software Engineer\n· Employment: Full-time",
		"electricalandElectronicsEngineerGeneralQualificationsTitle":"General Qualifications",
		"electricalandElectronicsEngineerRequiredSkillsTitle":"Required Skills",
  	"electricalandElectronicsEngineerPreferredTitle":"Preferred Skills",
		"electricalandElectronicsEngineer":"Electrical and Electronics Engineer",
  	"electricalandElectronicsEngineerPositionInformationTitle":"Position Details",
		"electricalandElectronicsEngineerFirstDescription":"\nWe are looking for Electrical and Electronics Engineer to join our growing engineering team.",
		"electricalandElectronicsEngineerGeneralQualifications":"\n· Bachelor's or Master’s degree Electrical and Electronics Engineering, Electronics and Communications Engineering, or another related field \n· Good spoken and written English \n· Teamwork and result oriented \n· Between the ages of 22-28 \n· No military obligation for male candidates",
		"electricalandElectronicsEngineerRequiredSkills":"\n· Interest in electronic hardware design \n· Knowledge in microcontrollers and other embedded system components \n· Knowledge in SFR structure and interrupt functioning \n· Preferably experience in electronic circuit design software such as Altium Designer, EAGLE, Proteus \n· Knowledge in C/C++ programming language, \n· Knowledge in Communication Protocols (CAN, SPI, I2C, etc.) \n· Ability to datasheets, schematics, and components",
		"electricalandElectronicsEngineerPreferred":"\n· Selection of hardware components according to design requirements \n· Establishing communication protocols between components \n· Schematic capture and PCB layout design \n· Processing sensor data and evaluation of sensors \n· Hardware failure detection and debugging \n· Prototype development and mass production",
		"electricalandElectronicsEngineerPositionInformation":"\n· Department: Big Data and Information Fusion \n· Position name: Electrical and Electronics Engineer \n Employment: Full-time",
		"apply": "APPLY"
	}

}
